<template>

	<header id="header-unsubscribe">
		<div class="header">
			<div class="logo">
				<router-link :to="{name: 'home'}">
					<img src="../../assets/img/logo.svg" alt="Logo">
					<span class="logo__text">
						readadventure
					</span>
				</router-link>
			</div>

			<div class="localization">
				<div class="active" @click="toggleLangSelector">
					<img :src="selector[activeLang].img" :alt="selector[activeLang].name">
				</div>
				<ul class="localization__list" :class="{'open': changeLang}">
					<li v-for="(language, key) in selector"  :key="key" @click="setLang(key)">
						<img :src="language.img" :alt="language.name">
					</li>
				</ul>
			</div>

			<div class="navbar-list" :class="{'wrapMenu toggleActive': showMenu, 'wrapMenu': !showMenu}">
				<nav class="nav">
					<ul class="menuList">

					<li @click="showMenu = false"><router-link :to="{name: 'home'}">{{ vocab[locale].nav.home }}</router-link></li>
<!--					<li @click="showMenu = false"><router-link :to="{name:'unsubscribe'}">{{ vocab[locale].nav.unsubscribe }}</router-link></li>-->
				</ul>
				</nav>
			</div>
			<div class="menu-icon" :class="{'effect': showMenu}" @click="showMenu = !showMenu">
				<div class="menu-icon-line"></div>
			</div>
		</div>
	</header>

</template>

<script>
	import { mapState, mapGetters, mapMutations } from 'vuex';
	export default {
		name: "Header",
		computed: {
			...mapState([
				'vocab', 'locale', 'selector', 'changeLang', 'specific'
			]),
			...mapGetters([
				'activeLang'
			])
		},
		methods:{
			...mapMutations([
				'toggleLangSelector', 'setLang'
			])
		},
		data(){
			return {
				showMenu: false,
			}
		}
	}
</script>

<style scoped>

</style>
